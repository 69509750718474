var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "menuForm",
    attrs: {
      inline: true,
      "label-width": "85px",
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      prop: "icon"
    }
  }, [_c("i", {
    staticClass: "icon",
    class: "el-icon-" + _vm.meta.icon,
    staticStyle: {
      position: "absolute",
      "z-index": "9999",
      padding: "5px 10px"
    }
  }), _c("el-select", {
    staticClass: "gva-select",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.meta.icon,
      callback: function callback($$v) {
        _vm.$set(_vm.meta, "icon", $$v);
      },
      expression: "meta.icon"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.key,
        value: item.key
      }
    }, [_c("span", {
      staticClass: "icon",
      class: item.label
    }), _c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(item.key))])]);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };